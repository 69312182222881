<template>
  <v-container>
    <v-row class="my-4">
      <v-col>
        <div class="text-h5" color="#000000DE">
          <v-icon left color="#000000DE">mdi-folder</v-icon>プロジェクト
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" lg="6">
        <v-text-field prepend-inner-icon="mdi-magnify" v-model="search" label="検索" hide-details outlined dense style="background-color: white;"></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" lg="6">
        <v-select @click="resetPage" v-model="selectedType" :items="typeOptions" label="絞り込み" dense outlined hide-details style="background-color: white;" :menu-props="{ offsetY: true }"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="d-flex flex-column justify-center align-center">
        <v-card width="100%" height="100%" min-height="160px" class="hovered" @click="dialogCreateProject = true">
          <v-row justify="center" align="center" class="fill-height pt-4">
            <v-col cols="12" class="d-flex flex-column justify-center align-center">
              <v-btn fab color="primary" class="elevation-0">
                <v-icon large>mdi-plus</v-icon>
              </v-btn>
              <span class="text-center text-body-1 pt-4" style="color: #1976D2;">新規プロジェクト</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="(project, index) in paginatedProjects" :key="index">
        <v-card class="hovered" @click="projectCardClick(project.ProjectId, project.Name, project.Type)">
          <v-card-title class="subtitle-2">{{ limitString(project.Name, 30) }}</v-card-title>
          <v-card-subtitle class="text-caption">
            <div v-if="project.Detail">{{ limitString(project.Detail, 15) }}</div>
            <div v-else>プロジェクトの説明はありません。</div>
          </v-card-subtitle>
          <v-card-text class="mb-0">
            <div class="text-caption">{{ formatDate(project.created) }}</div>
          </v-card-text>
          <v-card-actions>
            <v-chip :class="getClassByType(class_types[project.Type])" text-color="white" small label>
              <v-icon left color="white" small>{{ getClassificationIcon(class_types[project.Type]) }}</v-icon>
              {{ class_types[project.Type] }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="deleteProject(project.ProjectId, project.Name)">
                  <v-list-item-title>削除</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-pagination v-if="pagination.pages > 1" v-model="page" :length="pagination.pages" color="primary"></v-pagination>
      </v-col>
    </v-row>
    <v-dialog
      v-if="dialogCreateProject"
      v-model="dialogCreateProject"
      max-width="600"
    >
      <DialogCreateProject v-on:closeEmit="updateProjects" v-on:close="close" />
    </v-dialog>
    <v-dialog
      v-if="dialogDeleteProject"
      v-model="dialogDeleteProject"
      max-width="600"
    >
    <DialogDelete 
        v-on:closeDeleteEmit="close"
        v-on:deleteItemExeEmit="updateProjects"
        :name="deleteProjectName"
        :deletionType="deleteProjectId"
        :deletionTitle="deletionTitle"
      />
    </v-dialog>
    <loading v-if="loading"></loading>
  </v-container>
</template>
<script>
import commonMixin from '@/mixins/commonMixin.js';
const DialogCreateProject = () =>
  import("@/components/DialogCreateProject.vue");
const DialogDelete = () =>
  import("@/components/DialogDelete.vue");
const Loading = () =>
  import("@/components/Loading.vue");
export default {
  name: "Project",
  components: {DialogCreateProject , DialogDelete, Loading},
  mixins: [commonMixin],
  data() {
    return {
      selectedType: "全て",
      typeOptions: ["全て", "回帰分析", "多項分類", "時系列予測"],
      pagination: {
        pageSize: 7,
        pages: 1
      },
      page: parseInt(sessionStorage.getItem('currentPage')) || 1,
      dialogCreateProject: false,
      dialogDeleteProject:false,
      deleteProjectName:"",
      deleteProjectId:"",
      deletionTitle:"",
      projects: [],
      noProject:false,
      loading:false,
      search: "",
      sortDesc: true,
    };
  },
  async created() {
    await this.getProjects(true);
  },
  computed:{
    filteredProjects() {
      const keyword = this.search.toLowerCase().trim();
      const typeFilter = this.selectedType !== "全て" ? this.selectedType : "";
      return this.projects.filter(project =>
        (project.Name.toLowerCase().includes(keyword) ||
          project.Detail.toLowerCase().includes(keyword)) &&
        (typeFilter === "" || this.class_types[project.Type] === typeFilter)
      ).sort((a, b) => b.created - a.created);
    },
    paginatedProjects() {
      const start = (this.page - 1) * this.pagination.pageSize;
      const end = start + this.pagination.pageSize;
      return this.filteredProjects.slice(start, end);
    },
  },
  watch: {
    page(value) {
      if(this.search === ""){
        sessionStorage.setItem('currentPage', value);
      }
    },
    filteredProjects() {
      if (this.search !== "") {
        this.page = 1;
      }
      else{
        this.page = parseInt(sessionStorage.getItem('currentPage'));
      }
      this.updatePagination();
    }
  },
  mounted() {
    this.updatePagination();
  },
  methods: {
    resetPage() {
      this.page = 1;
    },
    updatePagination() {
      this.pagination.pages = Math.ceil(this.filteredProjects.length / this.pagination.pageSize);
    },
    async getProjects(reload) {
      this.loading = true;
      try {
        let data = this.$store.state.user_model["project"];
        if ((data == undefined) | (data.length == 0) | reload) {
          await this.$store.dispatch("setProjectInfo", this.$store.state.auth.login);
          data = this.$store.state.user_model["project"];
        }
        this.setupData(data);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    projectCardClick(projectId, projectName, classType) {
      this.$store.commit("setProjectName", projectName);
      this.$store.commit("setClassType", classType);
      this.$router.push(`/project/${projectId}`);
    },
    setupData(data) {
      this.projects = data;
      if(data.length == 0){
        this.noProject = true;
      }
      if (process.env.NODE_ENV === 'development') {
        console.log(data);
      }
    },
    deleteProject(projectId,projectName){
      if (process.env.NODE_ENV === 'development') {
        console.log(projectId)
      }
      this.deleteProjectId = projectId;
      this.deleteProjectName = projectName;
      this.deletionTitle = "プロジェクト";
      this.dialogDeleteProject = true;
    },  
    updateProjects(){
      this.close();
      this.getProjects(true);
    },
    close() {
      this.dialogCreateProject = false;
      this.dialogDeleteProject = false;
    },
  },
};
</script>

